import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Mindmirror</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know More
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
               {" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                What is Mindmirror
              </span>
            </h2>
            <p className="text-5">
            Convert the elements of your personality into a readable story. 
            Understand how your personality works. First, you have to take 
            a five minute test that gives out the a clear image of your 
            personality. Then, the results are utilized to create a story 
            of the interacting personality elements. This application is 
            meant to help everyone understand themselves and have an enjoyable
             external bird's eye view of their personality. This app is created
              with the help of an NLP practitioner and is based on the Big 5 test
            and a psychological concept named perceptual positioning.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  Big
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                5 Personality<span className="fw-700">Test</span>
              </h3>
            </div>
          </div>
        </div>
 
      </div>
    </section>
  );
};

export default AboutUs;
