import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Michael Carroll",
      desc: "Triple position can be used to help you step back from your own mental map to get distance from emotionally charged representations. You can also use the process to create empathy and even learn new things by getting valuable insights into another person’s map of the world.",
      icon: "fas fa-bullhorn",
      //url: "public/images/faq.png"
    },
    {
      name: "Psychology today",
      desc: "The Enneagram theory pre-dates the field of psychology and is an interpretation of ancient traditions regarding personality types. The Big Five evolved after decades of research on different personality traits and was the result of reducing dozens of proposed traits into five core, stable, “higher-order” traits.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "Very Well Mind",
      desc: "After taking a personality test and seeing our results, we can gain a better understanding of all the different perceptions and reactions that others might have to the same situation. This is important because we all have different ways of seeing and interacting with the world.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "Psychology Today",
      desc: "To know thyself is the beginning of wisdom. This famous quote is often attributed to Socrates. But what exactly do you know when you know yourself?",
      icon: "fas fa-bullhorn",
    },
    {
      name: "the baca institute",
      desc: "If you live your life from the outside in, your willingness to conform or desire to please interrupts your ability to connect with your inner wisdom.  Over time this becomes a self-defeating pattern. It becomes easier to conform than to act in alignment with what you feel is true.Realize that you must eventually redirect your attention to the source of wisdom that lives inside of you.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "PsychCentral",
      desc: "Listening to yourself is one of the best things you can do. It promotes happiness and can improve your well-being while making sure you’re on the best path for yourself. Learning how to listen to yourself is essential even though it takes practice, you can make it happen.You might feel a little unhappy, even when you follow your plan and reach your goals. On paper, it seems that your life is a success, but you won’t always feel that way. These feelings may imply that you aren’t listening to yourself.",
      icon: "fas fa-bullhorn",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What is it?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How can Mind Mirror help you?
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
